import {CountryKeys} from 'shared/lib'
import {StreamType, ImageType} from '../messages'

export enum ThreadTypeEnum {
  ACTIVE = 'active',
  REQUEST = 'request'
}

export type MailUserType = {
  id: string
  name: string
  age: number
  avatarUrl: string | null
  country: CountryKeys
  city: string | null
  isOnline: boolean
  isBlocked: boolean
  isConfirmed: boolean
  isPopular: boolean
}

export enum AttachmentTypeEnum {
  PHOTO = 'photo',
  VIDEO = 'video',
  AUDIO = 'audio'
}

export type PhotoContentAttachment = {
  photo: ImageType
  text: string | null
  purchased: boolean | null
  price: number
}

export type VideoContentAttachment = {
  video: StreamType
  duration: number
  text: string | null
  purchased: boolean | null
  price: number
}

export type AttachmentType = {
  id: string
  type: AttachmentTypeEnum
  content: PhotoContentAttachment | VideoContentAttachment
}

export type LetterType = {
  id: string
  text: string
  attachments: AttachmentType[]
  purchased: boolean
  dateCreated: string
  dateDeleted: string
  from: MailUserType
  to: MailUserType
}

export type LetterDraftType = {
  text: string
  photos: (ImageType & {isLoading?: boolean; isLocal?: boolean})[]
  videos: (StreamType & {isLoading?: boolean; isLocal?: boolean})[]
  dateCreated: string
  dateUpdated: string
}

export type ThreadType = {
  type: ThreadTypeEnum
  dateDeleted: string | null
  interlocutor: MailUserType
  lastLetter: LetterType | null
  draft: LetterDraftType | null
  lettersCount: number
  unreadCount: number
  pinned: boolean
  purchases: {
    letters: number
    videos: number
    photos: number
  }
}
