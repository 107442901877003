import {FC, MouseEvent, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {SetFavorite} from 'entities/activity/api'
import {reverseFavoriteInList} from 'entities/activity/model'
import {setTopicsFavorites} from 'entities/topics'
import {names} from 'shared/lib'
import {ActiveFavorite, DeactivateFavorite, Favorite} from 'shared/ui/icons'
import styles from './styles.module.scss'

type FavoriteButtonProps = {
  className?: string
  value: boolean
  wrapper?: string
  idUser?: string
  canChange?: boolean
  onChange?: (idUser: string, value: boolean) => void
}

export const FavoriteButton: FC<FavoriteButtonProps> = ({
  className,
  value,
  wrapper,
  idUser,
  canChange,
  onChange
}) => {
  const [isFavorite, setIsFavorite] = useState(value)
  const [isAnimationFavorite, setIsAnimationFavorite] = useState(false)
  const [isHoverFavorite, setIsHoverFavorite] = useState(false)
  const [isAfterAddAnimation, setIsAfterAddAnimation] = useState(false)
  const dispatch = useDispatch()

  const favoriteHandler = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation()
    if (isAnimationFavorite) return

    if (idUser) {
      SetFavorite({isFavorite, idUser})
        .then(() => {
          if (canChange) {
            dispatch(reverseFavoriteInList({id: idUser, isFavorite}))
          }
          if (onChange) {
            onChange(idUser, !isFavorite)
          }
          dispatch(setTopicsFavorites({idUser, value: !isFavorite}))
        })
        .catch(console.error)
    }

    if (!isFavorite) setIsAfterAddAnimation(true)
    setIsFavorite(!isFavorite)

    setIsAnimationFavorite(!isFavorite)
    setTimeout(
      () => setIsAnimationFavorite(false),
      Number(styles.animateTimeMs)
    )
  }

  useEffect(() => {
    setIsFavorite(value)
  }, [value])

  return (
    <div
      className={names(wrapper, styles.wrapper)}
      onClick={favoriteHandler}
      data-testid='favoriteButton'
      data-value={value}
    >
      <div
        className={`${className || ''} ${styles.iconButton} ${styles.favorite} ${isFavorite ? styles.active : ''} ${isAnimationFavorite ? styles.animate : ''}`}
        onMouseEnter={() => setIsHoverFavorite(true)}
        onMouseLeave={() => {
          setIsAfterAddAnimation(false)
          setIsHoverFavorite(false)
        }}
      >
        <div>
          {isFavorite ?
            isHoverFavorite && !isAnimationFavorite && !isAfterAddAnimation ?
              <DeactivateFavorite />
            : <ActiveFavorite />
          : <Favorite />}
        </div>
      </div>
    </div>
  )
}
