import {Axios} from '@asocial/fe-utils'
import {createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import {useSelector} from 'react-redux'
import {CursorType, ErrorResponsesType} from 'shared/types'
import {ThreadType} from '../../inbox/types'
import {DialogItemType} from '../../messages'

export enum RequestTypeEnum {
  CHAT = 'chat',
  MAIL = 'mail'
}

export type RequestType = {
  type: RequestTypeEnum
  entity: DialogItemType | ThreadType
}

type RequestsProps = {
  list: RequestType[]
  cursor: CursorType
  totalCount: number
  newList: string[]
}

export const initialRequestsModel: RequestsProps = {
  list: [],
  cursor: '',
  totalCount: 0,
  newList: []
}

export const requestsModel = createSlice({
  name: 'requests',
  initialState: initialRequestsModel,
  reducers: {
    addRequestsData: (state, {payload}: PayloadAction<GetRequestsResponse>) => {
      if (state.cursor === payload.cursor) return

      state.totalCount = payload.totalCount
      state.cursor = payload.cursor
      state.list.push(...payload.data)
    },
    addRequestsUp: (state, {payload: request}: PayloadAction<RequestType>) => {
      if (state.cursor === '') return

      if (state.list.find((item) => item.entity.interlocutor.id === request.entity.interlocutor.id)) {
        state.list = state.list.filter((item) => item.entity.interlocutor.id !== request.entity.interlocutor.id)
        state.totalCount--
      }

      state.list.unshift(request)
      state.newList.push(request.entity.interlocutor.id)
      state.totalCount++
    },
    deleteRequest: (state, {payload: idUser}: PayloadAction<string>) => {
      if (state.list.find((item) => item.entity.interlocutor.id === idUser)) {
        state.list = state.list.filter((item) => item.entity.interlocutor.id !== idUser)
        state.totalCount--
      }
    },
    clearNewRequest: (state) => {
      state.newList = []
    }
  }
})

export const {addRequestsData, addRequestsUp, clearNewRequest, deleteRequest} = requestsModel.actions

type GetRequestsResponse = {
  cursor: CursorType
  data: RequestType[]
  totalCount: number
}

export const GetRequests = (dispatch: Dispatch) =>
  useQuery<
    AxiosResponse<GetRequestsResponse>,
    ErrorResponsesType,
    AxiosResponse<GetRequestsResponse>,
    [CursorType, string]
  >({
    queryKey: [useSelector((state: RootState) => state.requests.cursor), 'getRequests'],
    queryFn: ({queryKey: [cursor], signal}) =>
      Axios.get('/activity/requests', {params: {cursor: cursor || null}, signal}).then((data) => {
        dispatch(addRequestsData(data.data))
        return data
      }),
    enabled: false,
    refetchOnWindowFocus: false
  })

export const useRequestsList = () => useSelector((state: RootState) => state.requests.list)

export const useRequestsNew = () => useSelector((state: RootState) => state.requests.newList)

export const useRequestsCursor = () => useSelector((state: RootState) => state.requests.cursor)

export const useRequestsTotalCount = () => useSelector((state: RootState) => state.requests.totalCount)
