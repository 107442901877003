import * as Sentry from '@sentry/react'

const MAIL_REGEX: RegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/
const MIN_CHAR_PASSWORD = 6

export const NUMBER_REGEX = /^[0-9\b]+$/

export const ZIP_REGEX = /^\d{5}(?:[-\s]\d{4})?$/

export const APPLE_IMAGE_REGEX = /\.(heic|heif)/

export const PASSWORD_MIN_CHAR_ERROR_TEXT = `Password must be at least ${MIN_CHAR_PASSWORD} characters`

export const F_P_LOCAL_STORAGE_KEY = 'fp_id'

export const isMailValid = (value: string) => MAIL_REGEX.test(value)

export const isPasswordValid = (password: string) => password.length >= 6

type MonthItem = {
  id: number
  label: string
}

type MonthKeys =
  | 'january'
  | 'february'
  | 'march'
  | 'april'
  | 'may'
  | 'june'
  | 'july'
  | 'august'
  | 'september'
  | 'october'
  | 'november'
  | 'december'

export const MonthItems: Record<MonthKeys, MonthItem> = {
  january: {
    id: 1,
    label: 'January'
  },
  february: {
    id: 2,
    label: 'February'
  },
  march: {
    id: 3,
    label: 'March'
  },
  april: {
    id: 4,
    label: 'April'
  },
  may: {
    id: 5,
    label: 'May'
  },
  june: {
    id: 6,
    label: 'June'
  },
  july: {
    id: 7,
    label: 'July'
  },
  august: {
    id: 8,
    label: 'August'
  },
  september: {
    id: 9,
    label: 'September'
  },
  october: {
    id: 10,
    label: 'October'
  },
  november: {
    id: 11,
    label: 'November'
  },
  december: {
    id: 12,
    label: 'December'
  }
}

export const MonthArray = Object.values(MonthItems)

export const getFPParams = () => {
  const fpObject = localStorage.getItem(F_P_LOCAL_STORAGE_KEY)
  const obj = {r: null, v: null}

  if (process.env.NODE_ENV === 'production' && !fpObject && !window.location.href.includes('asocial-team'))
    Sentry.captureException('No fp_id')

  try {
    if (!fpObject) return obj

    const {reqId, vId} = JSON.parse(fpObject)

    if (reqId) obj.r = reqId
    if (vId) obj.v = vId
  } catch (e) {
    console.error('Cannot parse fp object')
    Sentry.captureException(e)
  }

  return obj
}
