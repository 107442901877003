import {deleteToken, deleteUserLS, saveUserLS} from '@asocial/fe-utils'
import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {setUser as setSentryUser} from '@sentry/react'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {CountryKeys, isBonusLive} from 'shared/lib'
import {AUTH_TIME_SESSION_STORAGE_KEY} from 'shared/lib/constants'
import {Gender, InterestCategory, Me, SettingsKeys, UserStatus} from 'shared/types'

export type RegisterUserParams = {
  email: string
  password: string
  name: string
  dateBirth: string
  country?: CountryKeys | string
  gender?: Gender
  timezone?: string
}

export type RegisterUserResponse = {
  accessToken: string
  refreshToken: string
  expiresIn: number
  user: Me
}

export type GeoResponse = {
  country: CountryKeys
}

export type ValidationParams = {
  email: string
  password: string
}

export type ConfigResponse = {
  oauth: {
    googleClientId: string
  }
  turnstileSiteKey?: string
}

type googleMetaData = {
  primary?: boolean
  source: {
    type: string
    id: string
  }
  sourcePrimary: boolean
}

export type GoogleOAuthResponse = {
  resourceName: string
  etag: string
  names: {
    metadata: googleMetaData
    displayName: string
    familyName: string
    givenName: string
    displayNameLastFirst: string
    unstructuredName: string
  }[]
}

export type OAuthProps = {
  idToken: string
  name?: string
  dateBirth?: string | null
  gender?: Gender
  country?: CountryKeys
  timezone?: string
  turnstile?: string | null
}

export type UserModelProps = Me & {
  isAuthenticated: boolean
}

export const initialStateUserModel: UserModelProps = {
  id: '',
  profile: {
    name: '',
    gender: Gender.MALE,
    email: '',
    dateBirth: '',
    country: 'RU',
    city: null,
    maritalStatus: null,
    childrenCount: null,
    occupation: null,
    education: null,
    height: null,
    weight: null,
    drinking: null,
    smoking: null,
    about: null,
    avatarUrl: null,
    videoAvatarUrl: null,
    bannerUrl: null,
    isConfirmed: false,
    timezone: null,
    interests: []
  },
  paymentsCount: 0,
  status: UserStatus.ACTIVE,
  isAuthenticated: false,
  settings: null,
  support: {
    enabled: false,
    ts: '',
    lt: ''
  },
  wallet: {
    credits: 0,
    freeCredits: 0,
    photos: 0,
    stickers: 0,
    videos: 0,
    virtualGifts: 0
  },
  dateCreated: '',
  ipCountry: '',
  partner: null
}

export const userModel = createSlice({
  name: 'user',
  initialState: initialStateUserModel,
  reducers: {
    setUser: (state, {payload}: PayloadAction<Me | UserModelProps>) => {
      localStorage.setItem('isLogged', JSON.stringify({value: true}))
      setSentryUser({email: payload.profile.email, id: payload.id, username: payload.profile.name})
      if (!sessionStorage.getItem(AUTH_TIME_SESSION_STORAGE_KEY)) {
        sessionStorage.setItem(AUTH_TIME_SESSION_STORAGE_KEY, moment(new Date()).format())
      }
      return {...state, ...payload, isAuthenticated: true}
    },
    setAvatar: (state, {payload}: PayloadAction<string>) => {
      state.profile.avatarUrl = payload
      saveUserLS(state)
    },
    clearAvatar: (state) => {
      state.profile.avatarUrl = null
    },
    setSettingUser: (state, {payload: {key, value}}: PayloadAction<{key: SettingsKeys; value: any}>) => {
      state.settings = {...state.settings, [key]: value} as Record<SettingsKeys, any>
      saveUserLS(state)
    },
    setUserStatus: (state, {payload}: PayloadAction<UserStatus>) => {
      state.status = payload
      saveUserLS(state)
    },
    setUserEmail: (state, {payload: email}: PayloadAction<string>) => {
      state.profile.email = email
    },
    setUserInterests: (state, {payload: interests}: PayloadAction<InterestCategory[]>) => {
      state.profile.interests = interests
    },
    setConfirmEmail: (state, {payload}: PayloadAction<boolean>) => {
      state.profile.isConfirmed = payload
    },
    clearUser: () => {
      deleteUserLS()
      deleteToken()
      sessionStorage.removeItem(AUTH_TIME_SESSION_STORAGE_KEY)
      return initialStateUserModel
    }
  }
})

export const {
  setUser,
  clearUser,
  setAvatar,
  clearAvatar,
  setSettingUser,
  setUserStatus,
  setUserEmail,
  setConfirmEmail,
  setUserInterests
} = userModel.actions

export const useIsAuthenticated = () => useSelector((state: RootState) => state.user.isAuthenticated)

export const useUser = () => useSelector((state: RootState) => state.user)

export const useUserSettings = () => useSelector((state: RootState) => state.user.settings)

//delete everywhere after 2 weeks expired
export const useIsBonusLive = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.user.dateCreated,
      (dateCreated) => isBonusLive(dateCreated)
    )
  )

export const useSound = () =>
  useSelector(
    createSelector(
      (state: RootState) => state.user.settings?.isAudioEnabled,
      (isSound) => !(isSound === false)
    )
  )
