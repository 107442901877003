export const SEARCH_LIMIT = 25

export type TabKeys = 'all' | 'online' | 'new' | 'popular'

export type TabItem = {
  id: TabKeys
  label: string
}

export const tabItems: Record<TabKeys, TabItem> = {
  all: {
    id: 'all',
    label: 'All people'
  },
  online: {
    id: 'online',
    label: 'Online'
  },
  new: {
    id: 'new',
    label: 'New'
  },
  popular: {
    id: 'popular',
    label: 'Popular'
  }
}

export const tabList = Object.values(tabItems).sort((a, b) => Number(a.id) - Number(b.id))

export enum FindMeKeys {
  female = 'female',
  male = 'male',
  anyone = 'anyone'
}

export type FindMeItem = {
  id: FindMeKeys
  label: string
}

export const findMeItems: Record<FindMeKeys, FindMeItem> = {
  female: {
    id: FindMeKeys.female,
    label: 'Woman'
  },
  male: {
    id: FindMeKeys.male,
    label: 'Man'
  },
  anyone: {
    id: FindMeKeys.anyone,
    label: 'Anyone'
  }
}

export const findMeList = Object.values(findMeItems).sort((a, b) => Number(a.id) - Number(b.id))

export const minAge = 18
export const maxAge = 80

export const ageList = ['18', '20', '25', '30', '35', '40', '45', '50', '55', '60', '65', '70', '75', '80']

export type ContinentsItem = {
  order: number
  id: string
  label: string
}

type ContinentsKeys = 'northAmerica' | 'southAmerica' | 'eastEurope' | 'westEurope' | 'asia' | 'africa'

export const continentsItems: Record<ContinentsKeys, ContinentsItem> = {
  northAmerica: {
    order: 1,
    id: 'NORTH_AMERICA',
    label: 'North America'
  },
  southAmerica: {
    order: 2,
    id: 'SOUTH_AMERICA',
    label: 'South America'
  },
  eastEurope: {
    order: 3,
    id: 'EAST_EUROPE',
    label: 'East Europe'
  },
  westEurope: {
    order: 4,
    id: 'WEST_EUROPE',
    label: 'West Europe'
  },
  asia: {
    order: 5,
    id: 'ASIA',
    label: 'Asia'
  },
  africa: {
    order: 6,
    id: 'AFRICA',
    label: 'Africa'
  }
}

export const continentsList = Object.values(continentsItems).sort((a, b) => a.order - b.order)
