export type CursorType = string | null

export type TotalCountType = number | null

export type ErrorResponsesType = {
  data: {
    errors?: [{field: string; message: string}]
    error?: string
  }
  error?: string
  message: string
  status?: number
  responseError?: any
}

export enum SERVER_ERRORS {
  INSUFFICIENT_FUNDS = 'insufficient_funds'
}