type MirrorsKeys = 'gochatty' | 'lanadate'

export const Mirrors: Record<MirrorsKeys, string[]> = {
  gochatty: ['gochatty.asocial-team.com', 'gochatty.com'],
  lanadate: ['lanadate.asocial-team.com', 'lanadate.com']
}

const LOCAL_MIRROR = Mirrors.gochatty[0]
const DEFAULT_MIRROR = 'gochatty'

const getKeyFromEnumByValue = (enumValue: string): string => {
  for (const key of Object.keys(Mirrors)) {
    if (Mirrors[key as keyof typeof Mirrors].includes(enumValue)) {
      return key
    }
  }
  return DEFAULT_MIRROR
}

export const currentMirror = getKeyFromEnumByValue(
  process.env.NODE_ENV === 'development' ? LOCAL_MIRROR : window.location.hostname
) as MirrorsKeys

// Пример проверок в модулях
// if (Mirrors[currentMirror] === Mirrors.gochatty) {
//   console.log('logic')
// }

// if (Mirrors[currentMirror] === Mirrors.mirrortest) {
//   console.log('mirrortest')
// }
